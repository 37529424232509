<template>
  <div :style="{position: 'absolute', width: '90%', 'height': '200px', filter: 'blur(200px)', opacity: 0.5, 'background': randomColor}" />
</template>

<script>
export default {
  name: "BackgroundColor",
  data: () => ({
    randomColor: "",
  }),
  fetch () {
    this.randomColor = [
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
      "linear-gradient(90deg, rgba(121,9,9,1) 35%, rgba(255,136,0,1) 100%)",
      "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)",
      "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
      // 'linear-gradient(90deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)',
      "linear-gradient(90deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",
    ][Math.floor(Math.random() * 4)];
  },
};
</script>
